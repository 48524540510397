import { PhantomSolanaProvider } from '../../types';

const getProvider = (namespace = 'phantom'): PhantomSolanaProvider | undefined => {
  if (namespace in window) {
    const anyWindow: any = window;
    const provider = anyWindow[namespace]?.solana;

    if (provider?.isPhantom) {
      return provider;
    }
  }
};

export default getProvider;
