import React, { useState } from 'react';
import { EmbeddedSDKLoader } from '../../components/EmbeddedSDKLoader';
import { EmbeddedContainer } from '../../components/EmbeddedSDKLoader/EmbeddedContainer';
import Sidebar from '../../components/Sidebar';
import { AppWrapper } from '../../components/AppWrapper';
import { Phantom } from '@phantom/wallet-sdk';

export const App = () => {
  const [phantom, setPhantom] = useState<Phantom | null>(null);
  const [hideEmbeddedContainer, setHideEmbeddedContainer] = useState(false);
  return (
    <AppWrapper>
      <Sidebar logsVisibility={false} activePath="/embedded-sandbox">
        <EmbeddedSDKLoader
          onLoaded={(namespace = 'phantom', p: Phantom, useEmbeddedContainer: boolean) => {
            setPhantom(p);
            setHideEmbeddedContainer(!useEmbeddedContainer);
          }}
        />
        {!hideEmbeddedContainer && (
          <div style={{ height: '500px' }}>
            <EmbeddedContainer fullSize />
          </div>
        )}
      </Sidebar>
    </AppWrapper>
  );
};
