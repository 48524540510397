import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { GRAY, PURPLE, WHITE } from '../../constants';

import { hexToRGB } from '../../utils';
import Button from '../Button';

// =============================================================================
// Styled Components
// =============================================================================

const NavigationLink = styled(NavLink as any)`
  display: block;
  color: ${GRAY};
  text-decoration: none;
  font-size: 14px;
  padding: 8px 16px;
  background-color: ${hexToRGB(PURPLE, 0.2)};
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;

  &.active {
    font-weight: bold;
    color: ${PURPLE};
  }

  &:hover {
    color: ${PURPLE};
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
`;

const Menu = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

const Link = styled.a.attrs({
  href: '/',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-decoration: none;
  padding: 5px;
  &:focus-visible {
    outline: 2px solid ${hexToRGB(GRAY, 0.5)};
    border-radius: 6px;
  }
`;

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const MenuButton = styled(Button)`
  background-color: ${PURPLE};
  color: ${WHITE};
  width: 200px;

  &:hover {
    background-color: ${hexToRGB(PURPLE, 0.8)};
  }
`;

// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  activePath: string;
}

// =============================================================================
// Main Component
// =============================================================================

export const Header = React.memo((props: Props) => {
  const paths = [
    {
      name: 'Solana Sandbox',
      url: '/sol-sandbox',
    },
    {
      name: 'Ethereum Sandbox',
      url: '/eth-sandbox',
    },
    {
      name: 'Multi-Chain Sandbox',
      url: '/multi-chain-sandbox',
    },
    {
      name: 'BTC Sandbox',
      url: '/btc-sandbox',
    },
    {
      name: 'Sui Sandbox',
      url: '/sui-sandbox',
    },
    {
      name: 'Sui Embedded Sandbox',
      url: '/sui-embedded-sandbox',
    },
    {
      name: 'Solana Adapter Sandbox',
      url: '/sol-adapter-sandbox',
    },
    {
      name: 'Deeplinks Sandbox',
      url: '/deeplinks-sandbox',
    },
    {
      name: 'EIP-6963 Sandbox',
      url: '/eip-6963-sandbox',
    },
    {
      name: 'Rainbowkit Sandbox',
      url: '/rainbowkit-sandbox',
    },
    {
      name: 'Wagmi Sandbox',
      url: '/wagmi-sandbox',
    },
    {
      name: 'Web3 React V6 Sandbox',
      url: '/web3-react-v6-sandbox',
    },
    {
      name: 'Web3 React V8 Sandbox',
      url: '/web3-react-v8-sandbox',
    },
    {
      name: 'Blinks Sandbox',
      url: '/blinks-sandbox',
    },
    {
      name: 'Solana Malformed Requests Sandbox',
      url: '/sol-malformed-requests-sandbox',
    },
    {
      name: 'Solana Advanced Sandbox',
      url: '/sol-advanced-sandbox',
    },
    {
      name: 'Solana Embedded Sandbox',
      url: '/sol-embedded-sandbox',
    },
    {
      name: 'Ethereum Embedded Sandbox',
      url: '/eth-embedded-sandbox',
    },
    {
      name: 'Embedded Sandbox',
      url: '/embedded-sandbox',
    },
    {
      name: 'Provider Doctor',
      url: '/provider-doctor',
    },
  ];

  const activeMenuPage = paths.find((path) => path.url === props.activePath) || {
    name: 'Not Found',
    url: '/404',
  };

  const [sandboxMenuOpen, setSandboxMenuOpen] = React.useState(false);

  const toggleSandboxMenu = () => {
    setSandboxMenuOpen(!sandboxMenuOpen);
  };

  return (
    <Menu>
      <HeaderContainer>
        <Link>
          <img src="/images/phantom-icon-purple.png" alt="Phantom" width="48" />
        </Link>
        <MenuButton onClick={toggleSandboxMenu} data-testid="menu">
          {activeMenuPage.name} {sandboxMenuOpen ? '-' : '\u2630'}
        </MenuButton>
      </HeaderContainer>
      {sandboxMenuOpen && (
        <MenuContainer>
          {paths.map((path, i) => (
            <NavigationLink key={`${path.name}-${i}`} to={path.url}>
              {path.name}
            </NavigationLink>
          ))}
        </MenuContainer>
      )}
    </Menu>
  );
});
