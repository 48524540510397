import { LogsProvider } from '../../hooks/useLogs';
import { App } from '../sui-sandbox/App';
import React, { useState } from 'react';
import { PhantomSuiProvider } from '../../types';

import { EmbeddedSDKLoader } from '../../components/EmbeddedSDKLoader';
import { EmbeddedContainer } from '../../components/EmbeddedSDKLoader/EmbeddedContainer';

const AppWithProviders = () => {
  const [provider, setProvider] = useState<PhantomSuiProvider | null>(null);
  const [hideEmbeddedContainer, setHideEmbeddedContainer] = useState(false);
  return (
    <LogsProvider>
      <App provider={provider}>
        <div>
          <EmbeddedSDKLoader
            onLoaded={(namespace = 'phantom', _, useEmbeddedContainer) => {
              setProvider((window[namespace] as any)?.sui);
              setHideEmbeddedContainer(!useEmbeddedContainer);
            }}
          />
        </div>

        {!hideEmbeddedContainer && <EmbeddedContainer />}
      </App>
    </LogsProvider>
  );
};

export default AppWithProviders;
