import React from 'react';
import { AppWrapper } from '../../components/AppWrapper';
import styled from 'styled-components';
import { GRAY, LIGHT_GRAY, PURPLE, REACT_GRAY } from '../../constants';
import { hexToRGB } from '../../utils';
import { MadeWithLoveAtPhantom } from '../../components/MadeWithLoveAtPhantom';
import { deriveInjectionSpeedData, FAIL, PASS } from './utils';
import Button from '../../components/Button';

const Main = styled.main`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  background-color: ${REACT_GRAY};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100vh;

  > * {
    margin-bottom: 10px;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding-bottom: 60px; /* Add space for bottom bar */
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

const IconContainer = styled.a.attrs({
  href: '/provider-doctor',
})`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 5px;

  &:focus-visible {
    outline: 2px solid ${hexToRGB(GRAY, 0.5)};
    border-radius: 6px;
  }
`;

const SectionHeader = styled.h4`
  color: ${GRAY};
  margin: 16px 0 8px;
`;

const Code = styled.span`
  background-color: ${LIGHT_GRAY};
  border-radius: 6px;
  padding: 0.1em 0.2em;
  font-family:
    ui-monospace,
    SFMono-Regular,
    SF Mono,
    Menlo,
    Consolas,
    Liberation Mono,
    monospace;
  font-size: 85%;
  color: ${PURPLE};
`;

const BottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none; /* Hide by default */
  padding: 8px;
  gap: 8px;
  background-color: ${LIGHT_GRAY};
  border-top: 1px solid ${hexToRGB(GRAY, 0.2)};
  z-index: 100;

  @media (max-width: 768px) {
    display: flex; /* Show only on mobile/tablet */
  }
`;

const App = () => {
  const windowPhantom = (window as any)?.phantom;
  // Injection speed
  const solInjection = deriveInjectionSpeedData(windowPhantom?.solana);
  const evmInjection = deriveInjectionSpeedData(windowPhantom?.ethereum);
  const btcInjection = deriveInjectionSpeedData(windowPhantom?.bitcoin);

  // SOL
  const windowSolana = !!(window as any)?.solana;
  const windowPhantomSolana = windowPhantom?.solana;
  const solDisconnect = typeof windowPhantom?.solana?.disconnect === 'function';
  const solOn = typeof windowPhantom?.solana?.on === 'function';
  const solSignAndSendTransaction = typeof windowPhantom?.solana?.signAndSendTransaction === 'function';
  const solSignAndSendAllTransactions = typeof windowPhantom?.solana?.signAndSendAllTransactions === 'function';
  const solSignTransaction = typeof windowPhantom?.solana?.signTransaction === 'function';
  const solSignAllTransactions = typeof windowPhantom?.solana?.signAllTransactions === 'function';
  const solSignMessage = typeof windowPhantom?.solana?.signMessage === 'function';

  // EVM
  const windowEthereum = !!(window as any)?.ethereum;
  const windowPhantomEthereum = windowPhantom?.ethereum;
  const evmRequest = typeof windowPhantom?.ethereum?.request === 'function';
  const evmIsConnected = typeof windowPhantom?.ethereum?.isConnected === 'function';
  const evmOn = typeof windowPhantom?.ethereum?.on === 'function';

  // BTC
  const windowPhantomBitcoin = windowPhantom?.bitcoin;
  const btcRequestAccounts = typeof windowPhantom?.bitcoin?.requestAccounts === 'function';
  const btcOn = typeof windowPhantom?.bitcoin?.on === 'function';
  const btcSignPSBT = typeof windowPhantom?.bitcoin?.signPSBT === 'function';
  const btcSignMessage = typeof windowPhantom?.bitcoin?.signMessage === 'function';

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'instant' });
    }
  };

  return (
    <AppWrapper>
      <Main>
        <Body>
          {/* Icon */}
          <IconContainer>
            <img src="/images/phantom-icon-purple.png" alt="Phantom" width="75" />
          </IconContainer>

          <SectionHeader>Injection speed</SectionHeader>
          <p aria-label={`solana injection speed ${solInjection.latencyTestResultA11y}`}>
            Solana injection speed {solInjection.speedValueDisplay} - {solInjection.speedTestResultDisplay}
          </p>
          <p aria-label={`ethereum injection speed ${evmInjection.latencyTestResultA11y}`}>
            Ethereum injection speed {evmInjection.speedValueDisplay} - {evmInjection.speedTestResultDisplay}
          </p>
          <p aria-label={`bitcoin injection speed ${btcInjection.latencyTestResultA11y}`}>
            BTC injection speed {btcInjection.speedValueDisplay} - {btcInjection.speedTestResultDisplay}
          </p>

          <p aria-label={`solana injection latency ${solInjection.latencyTestResultA11y}`}>
            Solana injection latency {solInjection.latencyValueDisplay} - {solInjection.latencyTestResultDisplay}
          </p>
          <p aria-label={`ethereum injection latency ${evmInjection.latencyTestResultA11y}`}>
            Ethereum injection latency {evmInjection.latencyValueDisplay} - {evmInjection.latencyTestResultDisplay}
          </p>
          <p aria-label={`bitcoin injection latency ${btcInjection.latencyTestResultA11y}`}>
            BTC injection latency {btcInjection.latencyValueDisplay} - {btcInjection.latencyTestResultDisplay}
          </p>

          <SectionHeader id="solana-section">SOL provider</SectionHeader>
          <ul>
            <li aria-label={`SOL window.solana ${windowSolana ? 'pass' : 'fail'}`}>
              SOL provider at <Code>window.solana</Code> - {windowSolana ? PASS : FAIL}
            </li>
            <li aria-label={`SOL window.phantom.solana ${windowPhantomSolana ? 'pass' : 'fail'}`}>
              SOL provider at <Code>window.phantom.solana</Code> - {windowPhantomSolana ? PASS : FAIL}
            </li>
            <li aria-label={`SOL disconnect ${solDisconnect ? 'pass' : 'fail'}`}>
              SOL <Code>disconnect</Code> method - {solDisconnect ? PASS : FAIL}
            </li>
            <li aria-label={`SOL on ${solOn ? 'pass' : 'fail'}`}>
              SOL event listener <Code>on</Code> method - {solOn ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signAndSendTransaction ${solSignAndSendTransaction ? 'pass' : 'fail'}`}>
              SOL <Code>signAndSendTransaction</Code> method - {solSignAndSendTransaction ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signAndSendAllTransactions ${solSignAndSendAllTransactions ? 'pass' : 'fail'}`}>
              SOL <Code>signAndSendAllTransactions</Code> method - {solSignAndSendAllTransactions ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signTransaction ${solSignTransaction ? 'pass' : 'fail'}`}>
              SOL <Code>signTransaction</Code> method - {solSignTransaction ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signAllTransactions ${solSignAllTransactions ? 'pass' : 'fail'}`}>
              SOL <Code>signAllTransactions</Code> method - {solSignAllTransactions ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signMessage ${solSignMessage ? 'pass' : 'fail'}`}>
              SOL <Code>signMessage</Code> method - {solSignMessage ? PASS : FAIL}
            </li>
          </ul>
          <SectionHeader id="evm-section">EVM provider</SectionHeader>
          <ul>
            <li aria-label={`EVM window.etheremum ${windowEthereum ? 'pass' : 'fail'}`}>
              EVM provider at <Code>window.ethereum</Code> - {windowEthereum ? PASS : FAIL}
            </li>
            <li aria-label={`EVM window.phantom.ethereum ${windowPhantomEthereum ? 'pass' : 'fail'}`}>
              EVM provider at <Code>window.phantom.ethereum</Code> - {windowPhantomEthereum ? PASS : FAIL}
            </li>
            <li aria-label={`EVM request ${evmRequest ? 'pass' : 'fail'}`}>
              EVM <Code>request</Code> method - {evmRequest ? PASS : FAIL}
            </li>
            <li aria-label={`EVM isConnected ${evmIsConnected ? 'pass' : 'fail'}`}>
              EVM <Code>isConnected</Code> method - {evmIsConnected ? PASS : FAIL}
            </li>
            <li aria-label={`EVM on ${evmOn ? 'pass' : 'fail'}`}>
              EVM event listener <Code>on</Code> method - {evmOn ? PASS : FAIL}
            </li>
          </ul>
          <SectionHeader id="bitcoin-section">BTC provider</SectionHeader>
          <ul>
            <li aria-label={`BTC window.phantom.bitcoin ${windowPhantomBitcoin ? 'pass' : 'fail'}`}>
              BTC provider at <Code>window.phantom.bitcoin</Code> - {windowPhantomBitcoin ? PASS : FAIL}
            </li>
            <li aria-label={`BTC requestAccounts ${btcRequestAccounts ? 'pass' : 'fail'}`}>
              BTC <Code>requestAccounts</Code> method - {btcRequestAccounts ? PASS : FAIL}
            </li>
            <li aria-label={`BTC on ${btcOn ? 'pass' : 'fail'}`}>
              BTC event listener <Code>on</Code> method - {btcOn ? PASS : FAIL}
            </li>
            <li aria-label={`BTC signPSBT ${btcSignPSBT ? 'pass' : 'fail'}`}>
              BTC <Code>signPSBT</Code> method - {btcSignPSBT ? PASS : FAIL}
            </li>
            <li aria-label={`BTC signMessage ${btcSignMessage ? 'pass' : 'fail'}`}>
              BTC <Code>signMessage</Code> method - {btcSignMessage ? PASS : FAIL}
            </li>
          </ul>
        </Body>
        <MadeWithLoveAtPhantom />
      </Main>
      <BottomBar>
        <Button onClick={() => scrollToSection('solana-section')}>Scroll to Solana</Button>
        <Button onClick={() => scrollToSection('evm-section')}>Scroll to EVM</Button>
        <Button onClick={() => scrollToSection('bitcoin-section')}>Scroll to Bitcoin</Button>
      </BottomBar>
    </AppWrapper>
  );
};

export default App;
